import React from 'react';
import {IoIosArrowDropleftCircle} from 'react-icons/io';

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowDropleftCircle
        className={className}
        style={{ ...style, display: "block", color: "#5b2bba", width: "2rem" }}
        onClick={onClick}
      />
    );
}

export default SamplePrevArrow