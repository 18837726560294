import React from 'react';
import { Navbar, FooterTwo, Menu, Food } from '../components';

import { useTranslation } from 'react-i18next';

import { Enchiladas, EnchiladasPortada, Uchepo, UchepoPortada, Corundas, CorundasPortada, Mole, MolePortada } from '../models';
import { lifood } from '../assets';
import { HiArrowCircleDown } from 'react-icons/hi';

const Gastronomia = () => {
  const { t } = useTranslation(); 

  return (
    <div>
      <div className='px-3 flex-col justify-between'>
        <Navbar />
        <div className='to-rosa from-morado bg-gradient-to-l rounded-full mr-auto ml-auto' style={{width:'350px'}}>
          <p className='-mt-16 text-white text-center font-bold text-4xl p-4 lg:-mt-10'>{t("gastronomia_titulo")}</p>
        </div>

        <div className='lg:px-10 px-7 text-center'>
          <p className='pt-10 text-lg lg:text-center leading-9 '>Los platillos mostrados son de la autoría de la reconocida Cocinera Tradicional Michoacana <br /> <span className='font-bold'>Blanca Delia Villagómez Estrada</span> </p><br />
        </div>

        <div className='pb-10'>
          <Menu prev={'altar'} next={'arte'}/>
        </div>

        <div className='flex flex-wrap items-center justify-center gap-10 lg:px-52'>
          <div className='flex flex-wrap justify-center'>
            <Food glb={Uchepo} portada={UchepoPortada} titulo={'Uchepo'}/>
            <p className='px-7 text-justify text-lg leading-9'>{t("uchepo")}</p>
          </div>
          <div className='flex flex-wrap justify-center'>
            <Food glb={Corundas} portada={CorundasPortada} titulo={'Corunda'} />
            <p className='px-7 text-justify text-lg leading-9 '>{t("corunda")}</p>
          </div>
          <div className='flex flex-wrap justify-center'>
            <Food glb={Mole} portada={MolePortada} titulo={'Mole Michoacano'}/>
            <p className='px-7 text-justify text-lg leading-9 '>{t("mole")}</p>
          </div>
          <div className='flex flex-wrap justify-center'>
            <Food glb={Enchiladas} portada={EnchiladasPortada} titulo={'Enchiladas Placeras'}/>
            <p className='px-7 text-justify text-lg leading-9 '>{t("enchiladas")}</p>
          </div>
        </div>
        <div className='pt-20 px-7'>
            <p className='text-sm text-center'>Descubre su menu completo en Realidad Aumentada</p>
            <div className='text-3xl flex justify-center p-1'><HiArrowCircleDown /></div>
            <div className='flex justify-center p-1'><a href="https://lifood.app/restaurantes/lacasadeblanca"><img className='w-24' src={lifood} alt="lifood" /></a></div>
        </div>

        <div className='pt-10 px-7 md:px-20' >
          <p className='text-lg leading-9 text-justify'>{t('gastronomia_descripcion')}</p><br />
          <p className='text-lg leading-9 text-justify'>{t('gastronomia_descripcion2')}</p>
        </div>
        <FooterTwo />
      </div>
    </div>
  )
}

export default Gastronomia