import React, {useEffect} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Load} from './pages';
import ReactGA from 'react-ga4';


const Home = React.lazy(() => import('./pages/Home'));
const Inicio = React.lazy(() => import('./pages/Inicio'));
const Catedral = React.lazy(() => import('./pages/Catedral'));
const Catrina = React.lazy(() => import('./pages/Catrina'));
const Altar = React.lazy(() => import('./pages/Altar'));
const Gastronomia = React.lazy(() => import('./pages/Gastronomia'));
const Arte = React.lazy(() => import('./pages/Arte'));

ReactGA.initialize(process.env.REACT_APP_GA_APIKEY);

function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/my-path" });
  },);
  
  return (
    <div className='app'>
      <BrowserRouter>
        <div>
          <Routes>
              <Route path="/" element={
                <React.Suspense fallback={<Load/>}>
                  <Home />
                </React.Suspense>
              } />
              <Route path="/inicio" element={
                <React.Suspense fallback={<Load/>}>
                  <Inicio />
                </React.Suspense>
              } />
              <Route path="/catedral" element={
                <React.Suspense fallback={<Load/>}>
                  <Catedral />
                </React.Suspense>
              } />
              <Route path="/catrina" element={
                <React.Suspense fallback={<Load/>}>
                  <Catrina />
                </React.Suspense>
              } />
              <Route path="/altar" element={
                <React.Suspense fallback={<Load/>}>
                  <Altar />
                </React.Suspense>
              } />
              <Route path="/arte" element={
                <React.Suspense fallback={<Load/>}>
                  <Arte />
                </React.Suspense>
              } />
              <Route path="/gastronomia" element={
                <React.Suspense fallback={<Load/>}>
                  <Gastronomia />
                </React.Suspense>
              } />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;