import React from 'react';
import { Logo, LogoMorelia, LogoPatrimonio } from '../assets';
import { LanguageSelector } from '../components';

import { useTranslation } from 'react-i18next';

const Home = () => {

    // Hook importado de 'react-i18next'
    const { t } = useTranslation(); 

  return (
    <div className='home w-screen min-h-screen'>
        <div className='flex-col p-9'>
            <div className='flex justify-between'>
                <img src={LogoMorelia} alt="logoMorelia" className='w-20 h-20'/>
                <img src={LogoPatrimonio} alt="logoPatrimonio" className='w-20 h-20' />
            </div>
            <div className='flex justify-center pt-12 lg:pt-10'>
                <img src={Logo} alt="logo" className='w-56 md:w-72' />
            </div>
            <div className='flex-col'>
                <div className='text-white text-center'>
                    <h1 className='text-[2.45rem] font-semibold'>{t("home_1")} </h1>
                    <h2 className='text-[2rem]'>{t("home_2")}</h2>
                </div>
                <div>
                    <p className='text-white font-medium text-basis text-center'>{t("home_3")}</p>
                </div>

                {/* Iniciar Experiencia */}
                <div className='flex justify-center pt-6'>
                    <a href="/inicio" className='bg-naranja text-white text-xl font-bold border-10 py-6 rounded-2xl shadow-2xl w-screen lg:w-96 text-center hover:transition-all hover:duration-200 hover:bg-verde'>
                        {t('home_5')}
                    </a>
                </div>
                {/* Seleccionar Idioma */}
                    <div className='flex gap-3 justify-center pt-10 items-center'>
                    <LanguageSelector />
                </div>
                <div>
                    <p className='text-white font-thin text-xs text-center pt-6'>Powered by Live Technologies</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home