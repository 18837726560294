import React from 'react';
import {IoIosArrowDropleftCircle, IoIosArrowDroprightCircle} from 'react-icons/io';
import {AiFillHome} from 'react-icons/ai';

const Menu = ({prev, next}) => {
  return (
    <div className='flex justify-center pt-5'>
        <div className='flex justify-between p-2 text-3xl text-white rounded-full w-44 bg-gradient-to-l from-morado to-rosa'>
            <div>
                <a href={prev}><IoIosArrowDropleftCircle/> </a>
            </div>
            <div>
                <a href="/inicio"><AiFillHome/> </a>
            </div>
            <div>
                <a href={next}><IoIosArrowDroprightCircle /> </a>
            </div>
        </div>
    </div>
  )
}

export default Menu