import React from 'react';
import { HiOutlineCube } from 'react-icons/hi';

import '@google/model-viewer';

import { useTranslation } from 'react-i18next';

const Food = ({glb, portada, titulo}) => {
    const { t } = useTranslation(); 

    return (
        <div  style={{height:'350px', width:'350px'}}>
            <div className='px-7 md:px-20 p-2 bg-gradient-to-l from-morado to-rosa rounded-t-full lg:rounded-full text-white'>
                <h1 className='text-center font-bold text-2xl'>{titulo}</h1>
                <p></p>
            </div>
            <div className='flex justify-center'>
                <div>
                    <model-viewer
                        style={{height:'300px', width:'350px'}}
                        className='z-50'
                        src={glb} 
                        ar
                        ar-modes="webxr scene-viewer quick-look" 
                        camera-controls 
                        camera-orbit="0deg 0deg 1m" 
                        environment-image="neutral"
                        poster={portada}
                        loading="lazy"
                        progress
                        >
                        <div className="progress-bar hide" slot="progress-bar">
                            <div className="update-bar"></div>
                        </div>
                        <button 
                            className='rounded-b-full flex gap-2 justify-center items-center py-2.5 text-lg font-semibold uppercase text-white bg-gradient-to-l from-morado to-rosa w-full'
                            slot="ar-button" 
                            id="ar-button">
                            <HiOutlineCube className='text-lg'/>{t('boton3d')}
                        </button>
                        </model-viewer>
                </div>
            </div>
        </div>  
    )
}

export default Food