import React from 'react';
import { Logo } from '../assets';
import LanguageSelector from './LanguageSelector';

const Footer = () => {
  return (
    <div>
        <div className='absolute bottom-1 right-1/2 translate-x-1/2 logo -z-10'>
            <img src={Logo} alt="logo" className='w-[15rem]'/>
        </div>
        <div className='to-rosa from-morado bg-gradient-to-r rounded-t-full text-white text-center text-sm absolute bottom-0 left-0 w-full p-3'>
            <div className='flex justify-center pb-2'>
                <LanguageSelector/>
            </div>
            <div>
                <p> <i>Powered by Live Technologies</i></p>
            </div>
        </div>
    </div>
  )
}

export default Footer