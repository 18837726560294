import React from 'react';
import { Logo } from '../assets';
import LanguageSelector from './LanguageSelector';

const FooterTwo = () => {
  return (
    <div>
        <div className='logo -z-10 flex justify-center'>
            <img src={Logo} alt="logo" className='w-[15rem]'/>
        </div>
        <div className='to-rosa from-morado bg-gradient-to-r rounded-t-full text-white text-center text-sm'>
            <div className='flex justify-center pb-2 p-2'>
                <LanguageSelector/>
            </div>
            <div>
                <p> <i>Powered by Live Technologies</i></p>
            </div>
        </div>
    </div>
  )
}

export default FooterTwo