import { Altar, Biblioteca, Catedral, Catrina, Gastronomia, Guston, ReferenciaDos, ReferenciaTres, ReferenciaUno, Zalce } from "../assets";
import { SampleNextArrow, SamplePrevArrow } from "../components";

// Inicio
export const navLinks = [
    {
        id: "catedral",
        title: "inicio_1",
        secondTitle: "inicio_1_1",
        href:"/catedral",
        img: Catedral
    },    
    {
        id: "catrina",
        title: "inicio_2",
        secondTitle: "inicio_2_1",
        href:"/catrina",
        img: Catrina
    },    
    {
        id: "altar",
        title: "inicio_2",
        secondTitle: "inicio_2_2",
        href:"/altar",
        img: Altar
    },    
    {
        id: "gastronomia",
        title: "inicio_3",
        secondTitle: "inicio_1_1",
        href:"/gastronomia",
        img: Gastronomia
    },    
    {
        id: "arte",
        title: "inicio_5",
        secondTitle: "inicio_1_1",
        href:"/arte",
        img: ReferenciaDos,
    },
]

export const arteLinks = [
    {
      id:'kotler',
      title:'mural',
      link:'https://www.instagram.com/ar/384181370123787?utm_source=qr',
      img: ReferenciaUno,
      qr: Guston,
    },    
    {
      id:'biblioteca',
      title:'Biblioteca',
      link:'https://www.instagram.com/ar/568775457895672?utm_source=qr',
      img: ReferenciaDos,
      qr: Biblioteca,
    },    
    {
      id:'zalce',
      title:'Zalce',
      link:'https://www.instagram.com/ar/752436592796312?utm_source=qr',
      img: ReferenciaTres,
      qr: Zalce,
    },
  ];

export const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay:true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};