import React from 'react';
import { Navbar, FooterTwo, Menu } from '../components';
import { CatedraliOS, CatedralModelo, CatedralPortada } from '../models';
import { HiOutlineCube } from 'react-icons/hi'

import '@google/model-viewer';

import { useTranslation } from 'react-i18next';
import { CatedralImagen } from '../assets';

const Catedral = () => {
  
  const { t } = useTranslation(); 

  return (
    <div>
      <div className='px-3 flex-col justify-between'>
        <Navbar />

        <div className='to-rosa from-morado bg-gradient-to-l rounded-t-full mr-auto ml-auto lg:rounded-full' style={{width:'350px'}}>
          <p className='-mt-16 text-white text-center font-bold text-4xl pt-4 lg:pb-4 lg:-mt-10'>{t("catedral_titulo")}</p>
        </div>
        
        <div className='flex flex-wrap justify-center lg:flex-nowrap lg:flex-row-reverse lg:mr-10'>
          <div>
            {/* Model Viewer */}
            <div className='flex justify-center lg:-mt-12'>
              <model-viewer
                style={{height:'450px', width:'350px'}}
                className='z-50'
                src={CatedralModelo} 
                ios-src={CatedraliOS}
                ar
                ar-modes="webxr scene-viewer quick-look" 
                camera-controls 
                camera-orbit="140deg 75deg 1.7m" 
                environment-image="neutral"
                poster={CatedralPortada}
                loading="lazy"
                progress
                >
                  <div className="progress-bar hide" slot="progress-bar">
                      <div className="update-bar"></div>
                  </div>
                  <button 
                    className='rounded-b-full flex gap-2 justify-center items-center py-2.5 text-lg font-semibold uppercase text-white bg-gradient-to-l from-morado to-rosa w-full'
                    slot="ar-button" 
                    id="ar-button">
                      <HiOutlineCube className='text-2xl'/>{t('boton3d')}
                  </button>
                </model-viewer>
            </div>
            {/* Menu */}
            <div>
              <Menu prev={'arte'} next={'catrina'}/>
            </div>
          </div>

          <div className='flex pt-10 lg:px-20'>
            <div className='lg:block hidden'>
              <img src={CatedralImagen} alt="catrina" className='h-[500px] rounded-3xl' />
            </div>
            <div className='pt-10 px-7 lg:px-0 lg:pl-16 lg:pt-0' >
              <p className='text-lg leading-9'>{t('catedral_descripcion')}</p>
            </div>
          </div>
        </div>


        <FooterTwo />
      </div>
    </div>
  )
}

export default Catedral