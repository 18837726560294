import React from 'react';
import { Navbar, FooterTwo, Menu} from '../components';

import { HiOutlineCube } from 'react-icons/hi';

import Lottie from 'lottie-react';

import '@google/model-viewer';

import { useTranslation } from 'react-i18next';
import { AltarAnimacion, AltarModelo, AltarPortada } from '../models';
import { AltarImagen } from '../assets';


const Altar = () => {
  const { t } = useTranslation(); 

  return (
    <div>
      <div className='px-3 flex-col justify-between'>
        <Navbar />
        
        <div className='to-rosa from-morado bg-gradient-to-l rounded-t-full mr-auto ml-auto lg:rounded-full' style={{width:'350px'}}>
          <p className='-mt-16 text-white text-center font-bold text-4xl pt-4 lg:pb-4 lg:-mt-10'>{t("altar_titulo")}</p>
        </div>

        <div className='flex flex-wrap justify-center lg:flex-nowrap lg:flex-row-reverse lg:mr-20'>
          <div>
            {/* Model Viewer */}
            <div className='flex justify-center lg:-mt-10'>
              <model-viewer
                style={{height:'450px', width:'350px'}}
                className='z-50'
                src={AltarModelo} 
                ar
                autoplay
                ar-modes="webxr scene-viewer quick-look" 
                camera-controls 
                camera-orbit="5deg 75deg 5m" 
                environment-image="neutral"
                poster={AltarPortada}
                loading="lazy"
                progress
                >
                  <div className="progress-bar hide" slot="progress-bar">
                      <div className="update-bar"></div>
                  </div>
                  <button 
                    className='rounded-b-full flex gap-2 justify-center items-center py-2.5 text-lg font-semibold uppercase text-white bg-gradient-to-l from-morado to-rosa w-full'
                    slot="ar-button" 
                    id="ar-button">
                      <HiOutlineCube className='text-2xl'/>{t('boton3d')}
                  </button>
                </model-viewer>
            </div>
            {/* Menu */}
            <div>
              <Menu prev={'catrina'} next={'gastronomia'}/>
            </div>
          </div>

          <div className='flex pt-10 lg:px-20'>
            <div className='lg:block hidden'>
              <img src={AltarImagen} alt="catrina" className='h-[500px] rounded-3xl' />
            </div>
            <div className='pt-10 px-7 lg:px-0 lg:pl-16 lg:pt-0' >
              <p className='text-lg leading-9'>{t('altar_descripcion')}</p>
              <br className='hidden lg:block'/>
              <div className='flex justify-center lg:hidden'>
              <Lottie animationData={AltarAnimacion} loop={true} className='md:w-72'/>
            </div>
              <p className='text-lg leading-9'>{t('altar_descripcion2')}</p>
            </div>
          </div>

          {/* <div className='pt-10 px-7 md:px-20' >
            <p className='text-lg leading-9'>{t('altar_descripcion')}</p>
            <div className='flex justify-center lg:hidden'>
              <Lottie animationData={AltarAnimacion} loop={true} className='md:w-72'/>
            </div>
            <p className='text-lg leading-9'>{t('altar_descripcion2')}</p>
          </div> */}

        </div>

        <FooterTwo />
      </div>
    </div>
  )
}

export default Altar