import React from 'react'
import { Navbar, FooterTwo, Menu } from '../components';

import { arteLinks } from '../constants';
import { AiOutlineInstagram } from 'react-icons/ai';
import { IoIosCheckmarkCircleOutline, IoLogoInstagram, IoMdPhonePortrait} from 'react-icons/io';

import { useTranslation } from 'react-i18next';
import { HiArrowCircleRight } from 'react-icons/hi';

const Arte = () => {
    const { t } = useTranslation(); 

    return (
        <div className='px-3 flex-col justify-between'>
            <Navbar />
            <div className='to-rosa from-morado bg-gradient-to-l rounded-full mr-auto ml-auto' style={{width:'350px'}}>
                <p className='-mt-16 text-white text-center font-bold text-4xl p-4 lg:-mt-10'>{t("arte_titulo")}</p>
            </div>
            <div className='lg:w-[40rem] ml-auto mr-auto pt-10 px-4'>
                <div className='grid grid-cols-6 gap-4 pl-3 md:pl-6 lg:pl-0'>
                    <div className='text-lg flex items-center font-bold'> 1º<HiArrowCircleRight className='text-2xl' /> <AiOutlineInstagram className='text-2xl'/> </div>
                    <div className='col-span-5'><p className='lg:text-lg'>{t("instruccion_1")}</p> </div>
                    <div className='text-lg flex items-center font-bold'>2º <HiArrowCircleRight className='text-2xl' /> <IoMdPhonePortrait  className='text-2xl' /> </div>
                    <div className='col-span-5'><p className='lg:text-lg'>{t("instruccion_2")}</p></div>
                    <div className='text-lg flex items-center font-bold'> 3º<HiArrowCircleRight className='text-2xl' /> <IoIosCheckmarkCircleOutline className='text-2xl'/></div>
                    <div className='col-span-5'><p className='lg:text-lg'>{t("instruccion_3")}</p></div>
                </div>
            </div>
            <div>
                <Menu prev={'gastronomia'} next={'catedral'}/>
            </div>
            <div className='flex justify-center flex-wrap lg:gap-10 md:gap-10'>
                {arteLinks.map((arte) => (
                    <div key={arte.id} className='pt-5'>
                        <img src={arte.qr} alt={arte.id} className='w-44 rounded-3xl ml-auto mr-auto mb-5'/>
                        <img src={arte.img} alt={arte.id} className='h-96 rounded-t-2xl' />
                        <div className='bg-gradient-to-l from-morado to-rosa text-white p-4 rounded-b-2xl font-bold uppercase'>
                            <a href={arte.link} className='flex items-center justify-center gap-3'>
                                <IoLogoInstagram className='text-2xl'/>
                                <p>{t('instagram')}</p>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <FooterTwo />
        </div>
    )
}

export default Arte