import React from 'react';
import { navLinks, settings} from '../constants';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from 'react-i18next';

const Carousel = () => {
    const { t } = useTranslation(); 

    return (
        <div className='-mt-24 px-4'>
            <Slider {...settings}>
                {navLinks.map((link) => (
                    <div  key={link.id} className="p-2">
                    <div 
                    className='h-[25.5rem] lg:h-[25.5rem] md:h-[40rem] md:w-auto bg-cover rounded-[3.5rem] border-white border-8' 
                    style={{backgroundImage: `url(${link.img})`}}
                    >  
                        <div className='text-white p-5'>
                        <h1 className='font-bold text-3xl lg:text-2xl'>{t(link.title)}</h1>
                        <h2 className='text-4xl font-bold pt-3'>{t(link.secondTitle)}</h2>
                        </div>

                        <div className='flex justify-center z-10 lg:pt-[12rem] md:pt-[26.3rem] pt-[11.8rem]'>
                        <a 
                            href={link.href} 
                            className=' w-screen bg-gradient-to-l from-morado to-rosa  text-white text-xl font-bold border-10 py-6 rounded-[3.5rem] text-center hover:bg-gradient-to-b hover:transition-all hover:duration-200 '
                        >
                            {t('inicio_4')}
                        </a>
                        </div>

                    </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Carousel