import React from 'react';
import { useTranslation } from 'react-i18next';
import { English, French, Spanish } from '../assets';


const LanguageSelector = () => {

  // const handleChange = (lng) => { 
  //   localStorage.setItem('lng', lng)
  //   window.location.replace("?lng=" + lng);
  // }

  const {i18n} = useTranslation();

  function handleChange(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <div className='flex gap-2'>
      <button onClick={() => handleChange("es-MX")}> <img src={Spanish} alt="spanish" className='w-10 hover:scale-125 hover:duration-300 hover:transition-all' /> </button>
      <button onClick={() => handleChange("en")}> <img src={English} alt="english" className='w-10 hover:scale-125 hover:duration-300 hover:transition-all' /></button>
      <button onClick={() => handleChange("fr")}> <img src={French} alt="french" className='w-10 hover:scale-125 hover:duration-300 hover:transition-all' /> </button>
    </div>
  )
}

export default LanguageSelector

// export default LanguageSelector

// const LanguageSelector = () => {
//   const [lang, setLang] = useState('');

//   const handleChange = e => { 
//     setLang(e.target.value);
//     let loc = "/";
//     window.location.replace(loc + "?lng=" + e.target.value);
//   }

//   return (
//     <div>
//         <select value={lang} onChange={handleChange} className='rounded-full px-2 opacity-85 text-2xl w-20'>
//         {language.map(item => {
//             return (<option key={item.value} 
//             value={item.value}>{item.text}</option>);
//         })}
//         </select>
//     </div>
//   )
// }

// export default LanguageSelector