import React from 'react';
import { LogoMorelia, LogoPatrimonio } from '../assets';

const Navbar = () => {
  return (
    <div className='flex justify-between bg-gradient-to-l from-rosa to-morado pb-28 rounded-b-3xl p-7'>
        <img src={LogoMorelia} alt="logoMorelia" className='w-20 h-20'/>
        <img src={LogoPatrimonio} alt="logoPatrimonio" className='w-20 h-20'/>
    </div>
  )
}

export default Navbar