import React, { Component } from 'react';
import { Carousel, Footer, Navbar } from '../components';

// import {MdLanguage} from 'react-icons/md';


export default class Inicio extends Component {
  render() {

  return (
    <div>
      <div className='px-3 flex-col justify-between'>
        <Navbar />
        <Carousel />
        <Footer />
      </div>
    </div>
  )
  }
}