import React from 'react';
import {IoIosArrowDroprightCircle} from 'react-icons/io';

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <IoIosArrowDroprightCircle
        className={className}
        style={{ ...style, display: "block", color: "#5b2bba", width: "2rem"  }}
        onClick={onClick}
      />
    );
}

export default SampleNextArrow