import React from 'react';
import Lottie from 'lottie-react';
import { Refresh } from '../assets';

const Load = () => {
  return (
    <div className='mt-[20rem]'>
        <Lottie animationData={Refresh} loop={true} /> 
    </div>
  )
}

export default Load